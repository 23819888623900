import logo from './logo.svg';
import './App.css';
// import { GameSwipper } from './games/gameSwipper';
// import Countdown from './games/CountDown';
import GameResults from './games/GameResults';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <GameResults/>
      </header>
    </div>
  );
}

export default App;
