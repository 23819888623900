import GamePlatform from "./GamePlatform";

import { useEffect, useState } from "react";

export const gameStates = { init: 'INIT', failed: 'FAILED', success: 'SUCCESS' };

const GameDisplayer = ({ Game, setGameState, Message }) => {
    const [isSuccess, setSuccess] = useState(gameStates.init);
    const isPlaying = isSuccess === gameStates.init;
    const failed = isSuccess === gameStates.failed
    const GameName = Game?.displayName || Game.name;

    useEffect(() => {
        const newGameState = {
            GameName,
            isSuccess
        };
        setGameState(newGameState);
    }, [isSuccess]);
    useEffect(() => {
        setSuccess(gameStates.init)
    }, [ Game]);

    return (
        <div>
            {isPlaying && <GamePlatform Game={Game} setSuccess={setSuccess} />}
            {failed && <p>{Message}</p>}
            {(isSuccess === gameStates.success) && <p>Congrats</p>}
        </div>
    )
};
export default GameDisplayer;
