import React, { useState, useEffect, useCallback } from "react";

const useCountdown = ( num ) => {
  const [count, setCount] = useState(num);

  const delay = useCallback((ms) => new Promise((resolve) => setTimeout(resolve, ms)), []);

  const logCountdown = useCallback(async (count) => {
    if (count >= 0) {
      setCount(count); // Update the countdown state
      await delay(1000); // Wait for 1000 milliseconds (1 second)
      logCountdown(count - 1); // Recursively call with the next countdown value
    } else {
      setCount(-1);
    }
  }, [delay]);

  useEffect(() => {
    logCountdown(count); // Start the countdown when the component mounts

    // Clean up the interval when the component unmounts
    return () => {
      setCount(num); // Reset the countdown state
    };
  }, [count, logCountdown, num]);

  return count;
};

export default useCountdown;
