
import ClickBait from "./ClickBait";
import ClickBait2 from "./ClickBait2";
import Result from "./Result";
import Labyrinth from "./Labyrith";
import { useCallback, useState } from "react";
import GameDisplayer, { gameStates } from "./GameDisplayer";


const GameSequencer = ({setGameState}) => {
  console.log(ClickBait)
  const [currentGame, setCurrentGame] = useState(0);
  const Games = [ClickBait, Labyrinth, ClickBait2, Labyrinth];
  const Messages = ["are you vegan?", "what are your pronums?", "oh you are still playing...", "what ever"];
    console.log(currentGame, 'current game');
    
    
    const sequence = (gameIndex) => {
      if (gameIndex < Games.length) {
        setTimeout(() => {
          console.log('new gameIndex', gameIndex + 1);
          setCurrentGame(gameIndex + 1);
        }, intervalTime);
        return;
      }
      sequence(null);
    };

// Interval in milliseconds between element selections
const intervalTime = 1000*2; // 1 second

const update = useCallback((el) => {
  setGameState(el);
  if (el.isSuccess === gameStates.success || el.isSuccess === gameStates.failed) {
    sequence(currentGame);
  }
}, [currentGame]);
const Game = (() =>{
  if(currentGame !== null) {
    return Games[currentGame];
  }
  return null;
})();

const Message = (() =>{
  if(currentGame !== null) {
    return Messages[currentGame];
  }
  return null;
})();


    return (
        <div>
            {Game && <GameDisplayer setGameState={update} Game={Game} Message={Message}></GameDisplayer>}
        </div>
    )
};
export default GameSequencer;
