import React from "react";
import useCountdown from "./useCountDown";
import ClickBaitGame from "./ClickBaitGame";
// import useCountdown from "./useCountDown";


const GamePlatform =({Game, setSuccess}) => {
    const countDown = useCountdown(10);
return <ClickBaitGame countDown={countDown} Game={Game} setSuccess={setSuccess}/>
};

export default GamePlatform;