import React, { useState } from "react";

const ClickBait = ({onSuccess, clickedTime, onFailure}) => {
 

  // Step 2: Render the div with the onClick event handler
  return (
    <div>
      <div>What is the capital of washington</div>
      <button onClick={() => onSuccess(clickedTime)}
      > USA</button>
      <button onClick={() => onFailure(clickedTime)}>France</button>
      <button onClick={() => onFailure(clickedTime)}>Turkey</button>
      <button onClick={() => onFailure(clickedTime)}>Germany</button>
    </div>
  );
};

export default ClickBait;