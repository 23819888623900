import React, { useRef, useState } from "react";

const Labyrinth = ({onSuccess}) => {
  // Define the labyrinth layout (a 2D array of cells, represented by 0s and 1s)
  const labyrinthMap = [
    [1, 1, 1, 1, 1],
    [1, 0, 0, 0, 1],
    [1, 0, 1, 0, 1],
    [1, 0, 0, 0, 0],
    [1, 1, 1, 1, -1],
  ];

  const coloring = (cell) => {
    if(cell ===1 ) { return "pink" } 
    if (cell === 0) {return "blue"} 
    if (cell === -1) {return "red"}
    return null;
  }

  // Initialize the player's position
  const [playerPosition, setPlayerPosition] = useState({ x: 1, y: 1 });
  const isSuccess = useRef(false);

  // Handle keyboard input to move the player
  const handleKeyPress = (e) => {
    const { key } = e;
    let dx = 0;
    let dy = 0;

    switch (key) {
      case "ArrowUp":
        dy = -1;
        break;
      case "ArrowDown":
        dy = 1;
        break;
      case "ArrowLeft":
        dx = -1;
        break;
      case "ArrowRight":
        dx = 1;
        break;
      default:
        return;
    }

    // Calculate the new player position
    const newX = playerPosition.x + dx;
    const newY = playerPosition.y + dy;
    const canMove= newX >= 0 &&
    newY >= 0 &&
    newX < labyrinthMap[0].length &&
    newY < labyrinthMap.length;
    

    // Check if the new position is within the labyrinth boundaries and is not a wall (value of 1)
    if (
      canMove &&
      labyrinthMap[newY][newX] !== 1
    ) {
        if (labyrinthMap[newY][newX] === -1){
            onSuccess(true);
    
        }
      setPlayerPosition({ x: newX, y: newY });
    }
  };

  // Attach event listener to handle keyboard input
  React.useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [playerPosition]);

  // Render the labyrinth
  return (
    <div>
      <button onClick={()=>handleKeyPress({key:'ArrowUp'})}>up</button>
      <button onClick={()=>handleKeyPress({key:'ArrowDown'})}>down</button>
      <button onClick={()=>handleKeyPress({key:'ArrowLeft'})}>left</button>
      <button onClick={()=>handleKeyPress({key:'ArrowRight'})}>right</button>
        {isSuccess.current && 'hello'} status
      {labyrinthMap.map((row, y) => (
        <div key={y} style={{ display: "flex" }}>
          {row.map((cell, x) => (
            <div
              key={x}
              style={{
                width: "30px",
                height: "30px",
                backgroundColor: coloring(cell),
                border: "1px solid black",
                boxSizing: "border-box",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {playerPosition.x === x && playerPosition.y === y && "X"}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default Labyrinth;
