import React, { useState } from "react";

const ClickBait = ({onSuccess, clickedTime, onFailure}) => {
 

  // Step 2: Render the div with the onClick event handler
  return (
    <div>
      <div>Yinglun is smart</div>
      <button onClick={() => onSuccess(clickedTime)}
      > Fiction</button>
      <button onClick={() => onFailure(clickedTime)}>True</button>
    </div>
  );
};

export default ClickBait;