import React, { useState } from "react";
import { gameStates } from "./GameDisplayer";
// import useCountdown from "./useCountDown";

const isValidPositiveNumber = (num) => {
    return typeof num === "number" && num > 0;
  };

const GameBaitGame =({countDown, Game, setSuccess}) => {
    const isCountDown = isValidPositiveNumber(countDown);
    if(!isCountDown)
    {
      setSuccess(gameStates.failed);
    }
return <div> { countDown }
{ <Game onSuccess={()=>setSuccess(gameStates.success)} onFailure={()=>setSuccess(gameStates.failed)}></Game>}
</div>;
};

export default GameBaitGame;