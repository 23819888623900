
import { useState } from "react";
import GameSequencer from "./GameSequencer";
import { gameStates } from "./GameDisplayer";


const GameResults = () => {
  const [results, setResults] = useState(0);
  const handleResults= (el)=>{
    if(el.isSuccess === gameStates.success){
      setResults( res => res + 1);
    }
    console.log(el,'_RESULT_')};

  return <div>
    <div>Points:{results}</div>
    <GameSequencer setGameState={handleResults}></GameSequencer>
    </div>
};
export default GameResults;
